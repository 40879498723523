<template>
  <b-sidebar
    ref="sidebar"
    shadow
    backdrop
    right
    no-close-on-backdrop
    no-close-on-esc
    no-enforce-focus
    bg-variant="white"
    width="420px"
  >
    <template #header>
      <h3 class="my-1 mx-2 font-weight-bolder">
        <span v-if="!isUpdate"> {{ $tc('Global.New') }}&nbsp;</span>
        <span>{{ $t('Management.ServiceProviders.ServiceProvider') }}</span>
        <span v-if="isUpdate"> {{ $t('Global.Edit') }}</span>
      </h3>
    </template>
    <validation-observer
      ref="rules"
      tag="form"
      class="px-3 mt-2"
      @submit.prevent="submit"
    >
      <section v-if="serviceProvider" class="pb-3">
        <b-form-group
          :label="$t('Management.ServiceProviders.Name')"
          label-for="name"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('Management.ServiceProviders.Name')"
            rules="required"
          >
            <b-form-input
              id="name"
              v-model="serviceProvider.name"
              :placeholder="
                $t('Global.Write', {
                  subject: $t('Management.ServiceProviders.Name'),
                })
              "
              :state="errors.length > 0 ? false : null"
              :disabled="!can"
              autocomplete="off"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          :label="$t('Management.ServiceProviders.ContactPerson')"
          label-for="contactPerson"
        >
          <b-form-input
            id="contactPerson"
            v-model="serviceProvider.contactPerson"
            :placeholder="
              $t('Global.Write', {
                subject: $t('Management.ServiceProviders.ContactPerson'),
              })
            "
            :disabled="!can"
            autocomplete="off"
          />
        </b-form-group>
        <b-form-group
          :label="$t('Management.ServiceProviders.Street')"
          label-for="street"
        >
          <b-form-input
            id="street"
            v-model="serviceProvider.street"
            :placeholder="
              $t('Global.Write', {
                subject: $t('Management.ServiceProviders.Street'),
              })
            "
            :disabled="!can"
            autocomplete="off"
          />
        </b-form-group>
        <b-form-group
          :label="$t('Management.ServiceProviders.HouseNumber')"
          label-for="houseNumber"
        >
          <b-form-input
            id="houseNumber"
            v-model="serviceProvider.houseNumber"
            :placeholder="
              $t('Global.Write', {
                subject: $t('Management.ServiceProviders.HouseNumber'),
              })
            "
            :disabled="!can"
            autocomplete="off"
          />
        </b-form-group>
        <b-form-group
          :label="$t('Management.ServiceProviders.ZipCode')"
          label-for="zipCode"
        >
          <b-form-input
            id="zipCode"
            v-model="serviceProvider.zipCode"
            :placeholder="
              $t('Global.Write', {
                subject: $t('Management.ServiceProviders.ZipCode'),
              })
            "
            :disabled="!can"
            autocomplete="off"
          />
        </b-form-group>
        <b-form-group
          :label="$t('Management.ServiceProviders.City')"
          label-for="city"
        >
          <b-form-input
            id="city"
            v-model="serviceProvider.city"
            :placeholder="
              $t('Global.Write', {
                subject: $t('Management.ServiceProviders.City'),
              })
            "
            :disabled="!can"
            autocomplete="off"
          />
        </b-form-group>
        <b-form-group
          :label="$t('Management.ServiceProviders.Email')"
          label-for="email"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('Management.ServiceProviders.Email')"
            rules="required|email"
          >
            <b-form-input
              id="email"
              v-model="serviceProvider.email"
              :placeholder="
                $t('Global.Write', {
                  subject: $t('Management.ServiceProviders.Email'),
                })
              "
              :state="errors.length > 0 ? false : null"
              :disabled="!can"
              autocomplete="off"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          :label="$t('Management.ServiceProviders.Phone')"
          label-for="phone"
        >
          <b-form-input
            id="phone"
            v-model="serviceProvider.phone"
            :placeholder="
              $t('Global.Write', {
                subject: $t('Management.ServiceProviders.Phone'),
              })
            "
            :disabled="!can"
            autocomplete="off"
          />
        </b-form-group>
        <b-form-checkbox
          v-model="showStaffScheduling"
          switch
          class="pt-1"
          @change="clearStaffScheduling"
        >
          {{ $t('Management.ServiceProviders.StaffScheduling') }}
        </b-form-checkbox>
        <div v-if="showStaffScheduling">
          <validation-provider
            #default="{ errors }"
            :name="$t('Management.ServiceProviders.StaffScheduling')"
            tag="div"
            class="ml-4"
            rules="required"
          >
            <b-form-radio
              v-model="serviceProvider.staffSchedulingOver"
              name="staffScheduling"
              value="link"
            >
              {{ $t('Management.ServiceProviders.Link') }}
            </b-form-radio>
            <b-form-radio
              v-model="serviceProvider.staffSchedulingOver"
              name="staffScheduling"
              value="excel"
            >
              {{ $t('Management.ServiceProviders.Excel') }}
            </b-form-radio>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </div>
        <h4 class="mt-2">
          {{ $t('Management.ServiceProviders.HourlyRates') }}
        </h4>
        <div v-for="(layer, i) in layers" :key="layer.id">
          <b-form-group :label="layer.area" :label-for="layer.id">
            <validation-provider
              :ref="layer.id"
              #default="{ errors }"
              :name="$t('Management.ServiceProviders.HourlyRate') + ' ' + i"
              rules="number"
            >
              <b-form-input
                :id="layer.id"
                v-model="mockVModel[layer.id]"
                :placeholder="
                  $t('Global.Write', {
                    subject: $t('Management.ServiceProviders.HourlyRate'),
                  })
                "
                :state="errors.length > 0 ? false : null"
                :disabled="!can"
                autocomplete="off"
                @input="value => updateHourlyWage(value, layer)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>
      </section>
    </validation-observer>
    <template #footer>
      <div class="d-flex justify-content-between m-2">
        <b-button
          v-if="can"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="submit"
        >
          {{ $t('Global.Save') }}
        </b-button>

        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          @click="sidebarVisible = false"
        >
          {{ $t('Global.Retreat') }}
        </b-button>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BButton,
  BSidebar,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
  BFormRadio,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, number } from '@validations'

import { preventUnsavedChangesPopupLevel } from '@/utility/mixins/preventUnsavedChanges'

export default {
  components: {
    BButton,
    BSidebar,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BFormRadio,
  },

  mixins: [preventUnsavedChangesPopupLevel],

  props: {
    serviceProvider: {
      type: Object,
      default: () => null,
    },
    can: {
      type: Boolean,
      required: true,
    },
    isUpdate: {
      type: Boolean,
      default: () => false,
    },
    layers: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data: () => ({
    required,
    email,
    number,

    showStaffScheduling: false,
  }),

  computed: {
    mockVModel() {
      const dictionary = {}

      this.layers.forEach(x => {
        dictionary[x.id] = null
      })

      if (this.serviceProvider) {
        this.serviceProvider.hourlyWagesLayers.forEach(x => {
          dictionary[x.layer] = x.hourlyWage
        })
      }

      return dictionary
    },
  },

  watch: {
    serviceProvider(value) {
      if (!value) {
        this.showStaffScheduling = true
        return
      }
      if (value.staffSchedulingOver) this.showStaffScheduling = true
    },
  },

  methods: {
    clearStaffScheduling() {
      this.serviceProvider.staffSchedulingOver = null
    },

    reset() {
      this.$emit('reset')
      this.$refs.rules.reset()
    },

    async submit() {
      const valid = await this.$refs.rules.validate()

      if (valid) {
        this.$emit('submit')
        return
      }
      this.$alert.invalid()
    },

    async updateHourlyWage(value, layer) {
      const valid = await this.$refs[layer.id][0].validate()
      if (!valid) return

      const float = parseFloat(value)

      const index = this.serviceProvider.hourlyWagesLayers.findIndex(
        x => x.layer === layer.id,
      )

      if (isNaN(float)) {
        if (index !== -1) {
          this.serviceProvider.hourlyWagesLayers.splice(index, 1)
        }

        return
      }

      if (index > -1) {
        this.serviceProvider.hourlyWagesLayers[index].hourlyWage = float
        return
      }

      this.serviceProvider.hourlyWagesLayers.push({
        layer: layer.id,
        hourlyWage: float,
      })
    },
  },
}
</script>
