<template>
  <span>
    <feather-icon
      v-b-tooltip.hover.top="
        $t(
          `Global.${
            $can($acl.action.Update, $acl.subjects.ServiceProviders)
              ? 'EditSubject'
              : 'ViewSubject'
          }`,
          { subject: $t('Management.ServiceProviders.ServiceProvider') },
        )
      "
      :icon="
        $can($acl.action.Create, $acl.subjects.ServiceProviders)
          ? 'Edit2Icon'
          : 'EyeIcon'
      "
      size="16"
      class="hover-green"
      @click="openSideBar"
    />
    <Form
      ref="form"
      :service-provider="updatedData"
      :layers="layers"
      :submit-btn-text="`${$t('Global.Save')} ${$t(
        'Management.ServiceProviders.ServiceProvider',
      )}`"
      :can="$can($acl.action.Create, $acl.subjects.ServiceProviders)"
      :is-update="true"
      @submit="submit"
    />
  </span>
</template>

<script>
import Form from './form.vue'
import confrontImpactServiceProvider from '../utility/confrontImpactPopup'

export default {
  components: {
    Form,
  },

  props: {
    serviceProvider: {
      type: Object,
      required: true,
    },
    layers: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data: () => ({
    updatedData: null,
    staffSchedulingOverOld: null,
  }),

  watch: {
    updatedData(value) {
      if (!value) return
      this.staffSchedulingOverOld = value.staffSchedulingOver
    },
  },

  methods: {
    openSideBar() {
      this.updatedData = { ...this.serviceProvider }
      this.$refs.form.sidebarVisible = true
    },
    async submit() {
      try {
        if (
          typeof this.staffSchedulingOverOld === 'string' &&
          this.updatedData.staffSchedulingOver === null
        ) {
          const checkImpact = await this.$axios.get(
            `staff-scheduling/service-provider/remove-impact/${this.serviceProvider.id}`,
          )

          if (checkImpact.data !== 0) {
            const wannaDelete = await confrontImpactServiceProvider.call(
              this,
              checkImpact.data,
            )

            if (!wannaDelete) {
              this.updatedData.staffSchedulingOver = this.staffSchedulingOverOld
              this.$refs.form.showStaffScheduling = true

              return
            }

            await this.$axios.delete(
              `staff-scheduling/service-provider/remove/${this.serviceProvider.id}`,
            )
          }
        }

        const { data } = await this.$axios.patch(
          `service-providers/${this.serviceProvider.id}`,
          this.updatedData,
        )

        this.$alert.update(data.name)

        Object.keys(this.updatedData).forEach(key => {
          this.serviceProvider[key] = this.updatedData[key]
        })

        const { form } = this.$refs
        form.dirty = false
        form.sidebarVisible = false
      } catch {
        this.$alert.error()
      }
    },
  },
}
</script>
