<template>
  <div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      class="ml-2 d-flex align-items-center"
      variant="primary"
      @click="openSideBar"
    >
      <feather-icon class="mr-1" icon="DatabaseIcon" size="21" />
      {{ $tc('Global.New') }}
      {{ $t('Management.ServiceProviders.ServiceProvider') }}
    </b-button>
    <Form
      ref="form"
      :service-provider="serviceProvider"
      :layers="layers"
      :can="$can($acl.action.Create, $acl.subjects.ServiceProviders)"
      @submit="submit"
      @reset="reset"
    />
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import Form from './form.vue'

const serviceProviderFactory = () => ({
  name: null,
  contactPerson: null,
  street: null,
  zipCode: null,
  houseNumber: null,
  city: null,
  email: null,
  phone: null,
  active: true,
  serviceProvider: null,
  hourlyWagesLayers: [],
})

export default {
  components: {
    Form,
    BButton,
  },

  props: {
    layers: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data: () => ({
    serviceProvider: serviceProviderFactory(),
  }),

  methods: {
    reset() {
      this.serviceProvider = serviceProviderFactory()
    },
    openSideBar() {
      this.$refs.form.sidebarVisible = true
    },
    async submit() {
      try {
        const { data } = await this.$axios.post(
          'service-providers',
          this.serviceProvider,
        )
        this.$alert.create(`${data.name}`)
        this.$emit('add', data)

        const { form } = this.$refs
        form.dirty = false
        form.sidebarVisible = false
      } catch {
        this.$alert.error()
      }
    },
  },
}
</script>
