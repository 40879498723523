export default async function (count) {
  try {
    const messageVNode = this.$createElement('div', {
      class: ['p-1'],
      domProps: {
        innerHTML: this.$t(
          'Management.ServiceProviders.RemoveServerProviderText',
          {
            count,
          },
        ),
        style: 'font-size: 16px',
      },
    })

    return this.$bvModal.msgBoxConfirm([messageVNode], {
      title: this.$t('Management.ServiceProviders.RemoveServerProviderTitle'),
      size: 'sm',
      okVariant: 'primary',
      cancelVariant: 'outline-secondary',
      okTitle: this.$t('Global.Next'),
      cancelTitle: this.$t('Global.Retreat'),
      hideHeaderClose: false,
      centered: true,
      'body-class': 'p-2',
    })
  } catch {
    return false
  }
}
