<template>
  <section>
    <b-card>
      <h3 class="mb-1 font-weight-bolder">
        {{ $t('Global.Filters') }}
      </h3>
      <b-row>
        <b-col lg="3" md="4" sm="12">
          <label>
            {{ $t('Management.ServiceProviders.Status') }}
          </label>
          <v-select
            v-model="filter.status"
            :placeholder="$t('Global.Filter')"
            :options="states"
          >
            <template #option="{ label }">
              <div>{{ $t(`Management.ServiceProviders.${label}`) }}</div>
            </template>
            <template #selected-option="{ label }">
              <div>{{ $t(`Management.ServiceProviders.${label}`) }}</div>
            </template>
          </v-select>
        </b-col>
      </b-row>
    </b-card>
    <b-card no-body>
      <div class="d-flex custom-search row mb-2 px-2 pt-2 align-items-center">
        <div class="col-md-6">
          <h2 class="m-0 font-weight-bolder">
            {{ $t('Global.All') }}
            {{ $t('Management.ServiceProviders.ServiceProviders') }}
          </h2>
        </div>
        <div class="col-md-6 d-md-flex justify-content-end">
          <div class="d-flex align-items-center">
            <b-form-input
              v-model="searchTerm"
              :placeholder="$t('Global.Search')"
              type="text"
              class="d-inline-block search"
            />
            <Create
              v-if="$can($acl.action.Create, $acl.subjects.ServiceProviders)"
              :layers="layers"
              @add="addEntry"
            />
          </div>
        </div>
      </div>

      <Table
        :rows="filteredRows"
        :search-term="searchTerm"
        :layers="layers"
        @delete="deleteRow"
        @toggle-status="toggleStatus"
      />
    </b-card>
  </section>
</template>

<script>
import { BFormInput, BCard, BRow, BCol } from 'bootstrap-vue'
import vSelect from 'vue-select'

import deleteEntry from '@/utility/scripts/delete'
import Create from './components/create.vue'
import Table from './components/table.vue'

export default {
  components: {
    BFormInput,
    BCard,
    BRow,
    BCol,

    vSelect,

    Create,
    Table,
  },

  data: () => ({
    searchTerm: '',
    rows: [],
    states: ['Active', 'Inactive'],
    filter: {
      status: null,
    },
    layers: [],
  }),

  computed: {
    filteredRows() {
      return this.rows.filter(x => {
        if (this.filter.status === null) return true
        return x.active === (this.filter.status === 'Active')
      })
    },
  },

  created() {
    this.load()
    this.loadLayers()
  },

  methods: {
    async load() {
      try {
        const { data } = await this.$axios.get('service-providers')
        this.rows = data
      } catch (error) {
        console.error(error)
      }
    },

    async loadLayers() {
      const { data } = await this.$axios.get('layers')
      this.layers = data
    },

    addEntry(entry) {
      this.rows.push(entry)
    },

    async toggleStatus({ id, active }) {
      try {
        await this.$axios.patch(`service-providers/${id}`, { active })
        this.setPropertyFormServiceProvider(id, 'active', active)
        this.rows = JSON.parse(JSON.stringify(this.rows))
      } catch {
        this.$alert.error()
      }
    },

    setPropertyFormServiceProvider(id, key, value) {
      this.rows = this.rows.map(x => {
        if (x.id !== id) return x
        return {
          ...x,
          [key]: value,
        }
      })
    },

    async deleteRow(row) {
      await deleteEntry(this, {
        id: row.id,
        subject: `${this.$t('Management.ServiceProviders.ServiceProvider')}`,
        individual: `${row.name}`,
        endpoint: 'service-providers/',
      })
      this.load()
    },
  },
}
</script>
