var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
      _vm.$t(
        ("Global." + (_vm.$can(_vm.$acl.action.Update, _vm.$acl.subjects.ServiceProviders)
            ? 'EditSubject'
            : 'ViewSubject')),
        { subject: _vm.$t('Management.ServiceProviders.ServiceProvider') }
      )
    ),expression:"\n      $t(\n        `Global.${\n          $can($acl.action.Update, $acl.subjects.ServiceProviders)\n            ? 'EditSubject'\n            : 'ViewSubject'\n        }`,\n        { subject: $t('Management.ServiceProviders.ServiceProvider') },\n      )\n    ",modifiers:{"hover":true,"top":true}}],staticClass:"hover-green",attrs:{"icon":_vm.$can(_vm.$acl.action.Create, _vm.$acl.subjects.ServiceProviders)
        ? 'Edit2Icon'
        : 'EyeIcon',"size":"16"},on:{"click":_vm.openSideBar}}),_c('Form',{ref:"form",attrs:{"service-provider":_vm.updatedData,"layers":_vm.layers,"submit-btn-text":((_vm.$t('Global.Save')) + " " + (_vm.$t(
      'Management.ServiceProviders.ServiceProvider'
    ))),"can":_vm.$can(_vm.$acl.action.Create, _vm.$acl.subjects.ServiceProviders),"is-update":true},on:{"submit":_vm.submit}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }